class FormTemplate {
    constructor(data = {}) {
        this.id = data.id || null;
        this.name = data.name || "";
        this.schema = data.schema || {};
    }
}

class BaseTemplateResponse {
    constructor(data = {}) {
        this.id = data.id || null;
        this.template = data.template ? new FormTemplate(data.template) : null;
        this.response = data.response || {};
    }
}

const formTemplateModelMapping = {
    allFormTemplates: FormTemplate,
    formTemplate: FormTemplate,
    allBaseTemplateResponses: BaseTemplateResponse,
    baseTemplateResponse: BaseTemplateResponse,
    // Add other mappings here as needed
};

function processFormTemplateRelayData(inputObject) {
    for (let key in inputObject.data) {
        if (formTemplateModelMapping[key]) {
            const ModelClass = formTemplateModelMapping[key];

            // Handle array of instances (for "all" prefixed keys)
            if (key.startsWith("all") && inputObject.data[key].edges) {
                return inputObject.data[key].edges.map(
                    (edge) => new ModelClass(edge.node)
                );
            }

            // Handle single instance
            return new ModelClass(inputObject.data[key]);
        }
    }
    return null;
}

export { FormTemplate, BaseTemplateResponse, processFormTemplateRelayData };
